.loginFormWrapper {
}
.leftPanel {
  height: 100vh;
  background: url('../assets/images/home_background.png');
  background-size: cover;
  background-position: 100%;
  border-right: 5px solid #6a99ad;
  color: #fff;
  padding: 11% 5%;
  text-align: right;
}

.leftPanel h2 {
  color: #fff;
  font-size: 50px;
}
.rightPanel {
  height: 100vh;
  padding: 4% 5%;
}
.leftPanelTitle {
  color: red !important;
  font-size: 40px !important;
}
.rightPanelLogo {
  background: url("../assets/images/logo_bg.png") no-repeat;
  background-size: contain;
  background-position: center;
  height: 100px;
}
.formTitleText {
  width: 100%;
  display: block;
  font-family: Poppins-Regular;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding: 25px 0px;
}
input.input100 {
  height: 100%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #555555;
  line-height: 1.2;
  padding: 0 26px;
}
input {
  outline: none;
  border: none;
}

.bg-blue {
    background-color: #03A9F4 !important;
    border-color: #03A9F4 !important;
    color: #fff !important;
}
.blueBtn,
.blueBtn:hover {
  background: #2196f3;
  color: #fff;
  display: block;
  padding: 7px 12px;
  border-radius: 3px;
  border: 1px solid transparent;
}
.requireStar {
  color: #f00;
}
.table td {
  font-size: 1rem;
  font-weight: 400;
}
.inpFile::after{
    content: "Choose File";
    background-color: #03A9F4 !important;
    border-color: #03A9F4 !important;
    color: #fff !important;
}
.custom-file-inp {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  color: #000;
  border: 1px solid rgba(33, 47, 41, 0.2);
  background-color: transparent;
  padding: 0;
  width: 100%;
  height: 39px;
  border-radius: 5px;
}
.chooseFileLabel{
    background-color:  #9c9c9c !important;
    border-color: #03A9F4 !important;
    color: #fff !important;
    padding: 7px;
    margin-bottom: 0px;
    position: absolute;
    right: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
}
input[type="file"] {
  display: none;
}
.fileContainer {
  overflow: hidden;
  position: relative;
}
.fileContainer [type=file] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
}
.fileName {
  padding: 5px;
}
.formRow {
  flex-direction: row;
  align-items: right;
}
.rowBottom {
  margin-bottom: 1rem;
}
.mandatory-red {
  color: red;
  font-weight: bold;
  float: right;
}
.required-message {
  float: right;
  font-size: 12px;
  color: #808080;
}
.css-79elbk {
  position: inherit !important;
}

/* for toast */
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

/* Not needed with the cssTransition helper */
.animate {
  animation-duration: 800ms;
}

.cke_contents iframe
{
min-height: 700px !important;
}
/* organization  structure*/
.tableheader {
  background-color:#6a99ad;
  font-weight: bold;
  font-size: 16;
  margin-top: -20px;
  margin-left: -1px;
  margin-right: -1px;
 border: white;
  border-style: solid;
  border-width: thin;

}
.tablerow {
  background-color:lightgray;
  border: white;
  border-style: solid;
  border-width: thin;
  
  padding-top: 5px;

  
}
.tablebody {
  margin-top: -8px;
}
.grantotal {
  font-weight: bold;
  font-size: 20;
}
.previewtable{
  width: 100%;
  height:100px;
  border: black;
  border-style: solid;
  border-width: thin;
  
 

}
.previewhead{
  height:20px;
  background-color:lightgray;

  border: black;
  border-style: solid;
  border-width: thin;
  padding: 15px;
 

}
.previewcell{
  height:20px;
 

  border: black;
  border-style: solid;
  border-width: thin;
  padding: 15px;
 

}
.previewheader{
  width: 50%;
  height:30px;
  background-color:lightgray;

  border: black;
  border-style: solid;
  border-width: thin;
  
 

}
.previewrow{
  width: 100%;
  height:30pxpx;

  border: black;
  border-style: solid;
  border-width: thin;
  
 

}


.previewtext{
  width: 50%;
  height:30px;
 text-align:center;
font-style: normal;
font-size: larger;
border: black;
border-style: solid;
border-width: thin;

}

.Customcontainer
{
  margin-top: 10px;
  padding: 15px 15px 0px 15px !important;
 

}

.tab-content
{
  border: 1px solid #dee2e6;

    border-width: 1px;
    
    border-style: solid;
    border-color: rgb(222, 226, 230);

    /* border-top: none !important;
    text-align: right;
    direction:rtl; */
}

.tab-content .form-group
{
  margin-right: 15px;margin-left: 15px;
  border-top: none !important;
  
  padding-bottom: 15px;
 
}

.txtAr{
  
  
  text-align: right;
  direction:rtl;
  
 
}
.txtEng
{
  
  text-align: left;
  direction:ltr;
  
}

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
{ background-color: rgb(95, 137, 155) !important;
  color: #fff !important;}
  
.SaveButtons{padding-top:15px;text-align :center;}
a.nav-link.active {
  font-size: 16pt;
  font-weight: bolder;
}

.form-group label{
 font-family: 'ge_ss_text_Regular';
  font-weight: bold; 
 font-size: 11pt;
}


/* end css for toast */


