body {
  margin: 0;
  overflow-x: hidden !important;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  background-color: #F5F5F5;
  -moz-osx-font-smoothing: grayscale;
  font-family: "ge_ss_text_Regular";
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  --primaryBackground: #000000;
  --primaryTextColor: #ffffff;
  --primaryColor: #6a99ad;
  --btnColor: #6a99ad;
}

html[data-theme="dark"] {
  --primaryBackground: #ffffff;
  --primaryColor: #000;
  --primaryTextColor: #000000;
  --btnColor: #6a99ad;
}

html.theme-transition, html.theme-transition *, html.theme-transition *:before, html.theme-transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}
.loginBtn {
  color: var(--primaryTextColor);
}
.primaryBackgroundColor {
  background-color: var(--primaryColor);
}
.sideBarContainer {
  padding: 0px;
  background-color: #4f5f6f;
  min-height: 100vh;
}
.card-stats label:hover{
  text-decoration: underline !important;
}

/****** home page css start here - avinash *****/

.header {
  border-bottom: 5px solid #97b8c6;
}
.userNameTxt{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
.logo {
  width: 23vw;
  height: 13vh;
}
.navigation {
  padding: 3px 0;
  height: 6vh;
  background-color: #fafbfc;
}
.navigation a {
  color: #818181;
  font-size: 14px;
}
.navigation a:hover {
  color: #a4a4a4;
  font-weight: bold;
}
.menu1_ar {
  text-align: right;
  padding-right: 2vw;
  border-left: 2px solid #a4a4a4;
}
.menu1_en {
  text-align: left;
  padding-right: 2vw;
  border-right: 2px solid #a4a4a4;
}
.menu2_ar {
  padding-right: 2vw;
  border-left: 2px solid #a4a4a4;
  text-align: center;
}
.menu2_en {
  padding-right: 2vw;
  border-right: 2px solid #a4a4a4;
  text-align: center;
}
.loginbtn {
  border-radius: 40px;
  border: 1px solid #71757a;
  width: 10vw;
  background-color: transparent;
  color: #71757a;
}
.loginbtn:hover {
  background-color: #71757a;
  color: #ffffff;
}


.slider {
  text-align: center;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#243953+0,243953+0,207cca+53,243953+100 */
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#273f57+0,d8ded7+8,d8ded7+93,273f57+100 */
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#273f57+1,d8ded7+21,d8ded7+82,273f57+100 */
  background: #273f57;
  /* Old browsers */
  background: -moz-linear-gradient(left, #273f57 1%, #d8ded7 21%, #d8ded7 82%, #273f57 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #273f57 1%, #d8ded7 21%, #d8ded7 82%, #273f57 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #273f57 1%, #d8ded7 21%, #d8ded7 82%, #273f57 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#273f57', endColorstr='#273f57', GradientType=1);
  /* IE6-9 */
}
.sliderImage {
  height: 50vh;
  width: 65%;
}

.servicesBlock {
  background-color: #607e7f;
  min-height: 70vh;
  height: auto;
  border-top: 8px solid #6a99ad;
  background-image: linear-gradient(to right top, #051937, #1e475a, #547578, #94a49d, #d3d5d1);
}

.services {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  height: 18vh;
  /* height: auto; */
  border-radius: 15px;
  margin-top: 15vh;
  margin-left: 0px;
  margin-right: 0px;
  width:100%;
}

.services:hover {
  cursor: pointer;
  background-color: #6a99ad;
  border: 1px solid #6a99ad;
}

/* .services:hover .serviceInfo_ar {
  align-self: center;
} */

/* .services:hover .serviceInfo_ar:after {
  content: url('./assets/images/leftIcon.png');
  left: 0px;
  transform: translate(0,-5px);
  -webkit-transform: translate(0,-5px);
  -o-transform: translate(0,-5px);
  -moz-transform: translate(0,-5px);
} */

.services:hover .serviceInfo_ar, .services:hover .serviceInfo_en {
  transform: translate(0, -20px);
  -webkit-transform: translate(0, -20px);
  -o-transform: translate(0, -20px);
  -moz-transform: translate(0, -20px);
}

.serviceInfo_ar, .serviceInfo_en {
  /* -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in; */
  -webkit-transition: all 0.5s ease-in;
-moz-transition: all 0.5s ease-in;
-o-transition: all 0.5s ease-in;
-ms-transition: all 0.5s ease-in;
transition: all 0.5s ease-in;
}

/* .serviceInfo_ar:after {
  content: " ";
  display: table;
} */

.services:hover .serviceInfo_ar h4, .services:hover .serviceInfo_en h4 {
  color: #FFFFFF;
  font-weight: 900;
  align-self: center;
}

.services:hover p {
  color: #ffffff;
  font-weight: 700;
}

/* .services:hover .serviceImage {
  transform: scale(1.1);
} */

.serviceImage {
  margin-top: -3vh;
  float: right;
}

.serviceInfo_ar {
  /* padding: 20px 0; */
  text-align: right;
  align-self: flex-end;
}

.serviceInfo_ar h4 {
  color: #6a99ad;
  text-align: right;
  font-size: 16px;
  font-weight: 700;
}

.serviceInfo_en {
  padding: 20px 0;
  text-align: left;
  align-self: flex-end;

}

.serviceInfo_en h4 {
  color: #6a99ad;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.footerDesign {
  height: 13vh;
  background-image: url(assets/images/top-header-2.png);
  background-position: center;
  background-size: cover;
}

.footer {
  background-color: #6c757d;
  padding: 5px 0;
  height: 6vh;
}

.footer ul li {
  background-color: transparent;
  color: #FFFFFF !important;
  padding-top: 0;
  padding-bottom: 0;
}

.footer .list-group li {
  border-left: 1px solid #FFFFFF;
  border-left: none;
  border-top: none;
  border-bottom: none;
  font-size:12px;
}

.copyright_ar {
  height: 4vh;
  color: #fff;
  margin: 0;
  font-size:12px;
}

.copyright_en {
  height: 4vh;
  color: #fff;
  margin: 0;
  text-align: right;
  font-size:12px;
}

.footerMenu_ar {
  text-align: right;
  padding-right: 2vw;
  border-left: 2px solid #a4a4a4;
  color: #fff;
  height: 4vh;
}

.footerMenu_en {
  text-align: left;
  padding-left: 2vw;
  border-right: 2px solid #ffffff;
  color: #fff;
  height: 4vh;
}

.borderNone {
  border: none !important;
}

.footerMenu_ar a, .footerMenu_en a {
  color: #fff;
}

.socialIconBar_ar {
  position: fixed;
  left: 0;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
  width: 5%;
}

.socialIconBar_ar ul {
  list-style-type: none;
  padding-left: 15px;
}

.socialIconBar_en {
  position: fixed;
  right: 0;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
  width: 5%;
}

.socialIconBar_en ul {
  list-style-type: none;
  padding-right: 15px;
}

.socialIconBar_en a, .socialIconBar_ar a {
  display: block;
  text-align: center;
  /* padding: 5px 12px; */
  transition: all 0.3s ease;
  color: white;
  font-size: 16px;
  opacity: 0.5;
  border-bottom: 1px solid #fff;
}

.socialIconBar a:hover {
  background-color: #000;
}

.socialIcon {
  background: #6a99ad;
  color: white;
  max-height: 6vh;
  max-width: 6vw;
}

.carousel.carousel-slider .control-arrow {
  color: #000000 !important;
}

.carousel .control-prev.control-arrow:before, .carousel .control-next.control-arrow:before {
  color: #000000 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000000 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000000 !important;
}

.carousel .control-dots .dot {
  background-color: #000000 !important;
}

.btn-primary, .btn-primary:not([disabled]):not(.disabled):active {
  background-color: #4f5f6f !important;
  color: "white";
}

.loginbtnCol_en {
  text-align: right;
}

canvas {
  top: 0;
}

.carousel.carousel-slider .control-arrow:hover {
  background-color: transparent !important;
}

/****** home page css end here - avinash *****/

/* Header & SideBar */

a, p, input, span, textarea {
  font-family: "ge_ss_text_Regular";
  font-weight: 400;
  font-size: 16px;
}

.sideBarContainer div p {
  font-family: "ge_ss_text_Regular";
  font-size: 14px;
}

.gridActionBtn {
  color: #FFFFFF !important;
  border: 0px !important;
  background-color: #9c9c9c !important;
}

.MDBInputBorderOverRide, .MDBInputBorderOverRide:focus {
  border: 1.5px solid var(--primaryColor) !important;
  border-radius: 8px !important;
}

/*********** header changes ***********/

.headerMain .btn {
  box-shadow: none !important;
  text-transform: capitalize !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: #fff;
}
.headerMain span {
  font-size: 13px;
  /* color :#fff; */
}
.headerMain .show .btn {
  background-color: #5A8293;
}
.headerMain .dropdown-item {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.headerMain .dropdown-menu {
  border-radius: 0 0 .25rem .25rem;
  margin-top: 0px !important;
}
.headerMain .btn-group-sm>.btn {
  padding: 0px !important;
}
.headerMain i {
  padding: 5px;
}
.headerMain span.commentsBadge {
  background-color: #FF7043;
  border-color: #FF7043;
  color: #fff;
  position: absolute;
  top: 3px;
  padding: 3px;
  border-radius: 50%;
  font-size: 11px;
}
.headerMain{
  background-color: #09243e; 
  margin-bottom: 1;
  display: flex;
  justify-content: space-between; 
  align-items: space-between;
}
.headerRightBox{
  width: 50vw;
  display: flex;
  align-self: center;
  align-items: center;
  padding-right: 0;
  justify-content: flex-end;
  background-color: #d7dde4;
  padding-left: 0px;
  background-color: #09243e;
  /* box-shadow: 0px 0px 8px 0px #6f5050; */
  /* box-shadow: 0px 15px 10px -15px #111;  */
}
.headerRightBoxAdsPOp{
  width: 50vw;
  display: flex;
  align-self: center;
  align-items: center;
  padding-right: 0;
  justify-content: flex-end;
  background-color: #d7dde4;
  padding-left: 0px;
  /* background-color: #09243e; */
  /* box-shadow: 0px 0px 8px 0px #6f5050; */
  /* box-shadow: 0px 15px 10px -15px #111;  */
}
.headerMiddleBox{
  width: 50vw;
  display: flex;
  justify-content: flex-end;
  background-color: #09243e;
  /* box-shadow: 0px 0px 8px 0px #6f5050; */
  /* box-shadow: 0px 15px 10px -15px #111;  */
  z-index: 1;
  padding: 0px;
}
.headerLeftBox{
  background-color: #384655;
  display: flex;
  align-items: center;
}
.logoBox{
  cursor: pointer;
  color: white;
  /* background-color:#fff; */
  padding: 10px 10px 10px 10px;
}
.imgLogo{
  cursor: pointer; 
  width: 200px;
}
/* end header css */

/* Start Dashboard CSS */
.dashboardBox{
  background-color: #F5F5F5;
  display: flex;
  height: auto;
  padding-top: 10px;
  padding-bottom: 60px;
}

/* End Dashboard CSS */

div, span, label, input {
  font-family: 'ge_ss_text_Regular', "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif !important;
}
.validationMsg {
  color: #cccccc;
}
.textRed {
  font-size: 13px !important;
  color: #dc3545;
}
.invalid-feedback{
  font-size: 13px !important;
}

.pencilIcon {
  font-size: 20px !important;
  color: #ffffff !important;
  background-color: #00BCD4;
  display: inline-block;
  top: 0;
  width: 51px;
  height: 41px;
  border: 2px solid #eeeeee;
  font-size: 20px;
  border-radius: 50%;
  z-index: 10;
  line-height: 34px;
  text-align: center;
}

.iconNumber {
  font-size: 20px !important;
  color: #000000 !important;
  background-color: #eeeeee;
  display: inline-block;
  top: 0;
  width: 51px;
  height: 41px;
  border: 2px solid #eeeeee;
  font-size: 20px;
  border-radius: 50%;
  /* z-index: 10; */
  line-height: 34px;
  text-align: center;
}

.sideBarContainer div p {
  font-family: 'ge_ss_text_Regular';
  font-weight: 400;
  font-size: 16px;
}

.textGrey {
  color: #999999;
  font-size: 14px;
}

.sibebarLink:hover {
  cursor: pointer;
}

.navigation .list-group li {
  font-size: 14px;
  border-left: 2px solid #a4a4a4;
}

.navigation .list-group li:hover, .footer .list-group li:hover{
  cursor: pointer;
}

.navigation .list-group-item.active {
  background-color: #4D4D4D !important;
  color: #FFFFFF;
  border-radius: 0;
}

.navigation .list-group-item {
  border: none;
  background-color: transparent;
}

.hratablerow td{
  padding-top: 10px !important;
  padding-bottom: 0px !important;
  padding-left: 10px;
  padding-right: 10px;
}

.hratablerow th{
  padding: 10px !important;
}

.hratablerow div.dataTables_wrapper div.dataTables_info, .hratablerow div.dataTables_wrapper div.dataTables_paginate{
  padding-top:0px;
  padding-bottom:0px;
}

.hratablerow .dataTables_filter{
  float: right;
}
.react-datepicker__input-container
{
  width: 100%  !important
}
.react-datepicker-wrapper
{
  width:100%;
}
.comment_ar .close{
  margin-left: unset;
  float: left;
}
.modal-title {
  width: 100%;
}
.popover .arrow {
  display: none !important;
}

/* sidebar images */
.subMenuBox{
  background-color: #40505f;
  padding: 15px;
}
.no-gutter{
  padding: 15px;
  display: flex;
}
.menuSpan{
  background-repeat: no-repeat;
  width: 35px;
}
.metismenu{
  background: #6a99ad;
}
.metismenu-link{
  color: #fff !important;
}
.dashBoardBg{
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  background-color: #98b7c9;
}

.fa-dashboard{
    /* background-image: url('./assets/images/icons/sidebar_new_icon/dashboard.png'); */
    background-image: url('./assets/images/sidebar/dashboard_white.png');
    height: 25px;
    background-position: center;
}
.fa-sci{
  /* background-image: url('./assets/images/icons/sidebar_new_icon/sci.png'); */
  background-image: url('./assets/images/sidebar/sci.png');
  height: 25px;
  background-position: center;
}
.fa-task{
  /* background-image: url('./assets/images/icons/sidebar_new_icon/sci.png'); */
  background-image: url('./assets/images/sidebar/task.png');
  height: 25px;
  background-position: center;
}
.fa-report{
  /* background-image: url('./assets/images/icons/sidebar_new_icon/sci.png'); */
  background-image: url('./assets/images/sidebar/reporticon.png');
  height: 25px;
  background-position: center;
  padding: 10px !important;
}
.fa-information_request{
  background-image: url('./assets/images/sidebar/information_request.png');
  height: 25px;
  background-position: center;
}
.fa-exception_from_acccompany_patient{
  background-image: url('./assets/images/sidebar/exemption_from_accompany_patient_outside_of_country.png');
  height: 25px;
  background-position: center;
}
.fa-review_hr_policies{
  background-image: url('./assets/images/sidebar/review_the_hr_policies.png');
  height: 25px;
  background-position: center;
}
.fa-expection_housing_policy{
  background-image: url('./assets/images/sidebar/exception_of_housing_policy.png');
  height: 25px;
  background-position: center;
}
.fa-secondement_forfederalagency{
  background-image: url('./assets/images/sidebar/secondmend_for_federal_agency.png');
  height: 25px;
  background-position: center;
}
.fa-exceptionOfSteerTheAgeOfRetirementRequest{
  background-image: url('./assets/images/sidebar/exception_of_steer_age_of_retirement_request.png');
  height: 25px;
  background-position: center;
}
.fa-bonus_request{
  background-image: url('./assets/images/sidebar/bonus_request.png');
  height: 25px;
  background-position: center;
}
.fa-amend_salaries_scale{
  background-image: url('./assets/images/sidebar/amend_salary_scale.png');
  height: 25px;
  background-position: center;
}
.fa-Amend_employee_saleries{
  background-image: url('./assets/images/sidebar/amend_employee_salary.png');
  height: 25px;
  background-position: center;
}
.fa-manager_wizard{
  background-image: url('./assets/images/sidebar/connect_to_hra_white.png');
  height: 25px;
  background-position: center;
}
.fa-policies{
  background-image: url('./assets/images/sidebar/policies_white.png');
  height: 25px;
  background-position: center;
}
.fa-saleries_and_bonuos{
  background-image: url('./assets/images/sidebar/salaries_and_bonuses_white.png');
  height: 25px;
  background-position: center;
}
.fa-retirement_wizard{
  background-image: url('./assets/images/sidebar/exception_of_steer_age_of_retirement_request.png');
  height: 25px;
  background-position: center;
}
.fa-orgStructure{
  background-image: url('./assets/images/sidebar/orgnisation_structure-white.png');
  height: 25px;
  background-position: center;
}
.fa-admin_header{
  background-image: url('./assets/images/sidebar/admin_pannel_white.png');
  height: 25px;
  background-position: center;
}
.fa-inbox{
  background-image: url('./assets/images/sidebar/inbox.png');
  height: 25px;
  background-position: center;
}
.fa-inbox:before {
  content: '';
}
.fa-inporgress{
  background-image: url('./assets/images/sidebar/in_progress.png');
  height: 25px;
  background-position: center;
}
.fa-pending{
  background-image: url('./assets/images/sidebar/pending_icon.png');
  height: 25px;
  background-position: center;
}
.fa-completed{
  background-image: url('./assets/images/sidebar/completed.png');
  height: 25px;
  background-position: center;
}
.fa-onhold{
  background-image: url('./assets/images/sidebar/onhold_icon.png');
  height: 25px;
  background-position: center;
}
.fa-cancelled{
  background-image: url('./assets/images/sidebar/cancelled.png');
  height: 25px;
  background-position: center;
}
.fa-entities{
  background-image: url('./assets/images/sidebar/entities_gray.png');
  height: 25px;
  background-position: center;
}
.fa-roles{
  background-image: url('./assets/images/sidebar/roles_gray.png');
  height: 25px;
  background-position: center;
}
.fa-mailTemplate{
  background-image: url('./assets/images/sidebar/mail_templte.png');
  height: 25px;
  background-position: center;
}
.fa-lookUp{
  background-image: url('./assets/images/sidebar/lookup.png');
  height: 25px;
  background-position: center;
}
.fa-contact{
  background-image: url('./assets/images/sidebar/contact_gray.png');
  height: 25px;
  background-position: center;
}
.fa-dataServices{
  background-image: url('./assets/images/sidebar/data_services_white.png');
  height: 25px;
  background-position: center;
}
.fa-ExtendCreateObjectives{
  background-image: url('./assets/images/sidebar/extend_create_objctive.png');
  height: 25px;
  background-position: center;
}
.fa-extendReviewObjectives{
  background-image: url('./assets/images/sidebar/extend_review_objectives.png');
  height: 25px;
  background-position: center;
}
.fa-extendAnnualPerformanceEvaluationInterval{
  background-image: url('./assets/images/sidebar/extend_annual_performance_evlusion.png');
  height: 25px;
  background-position: center;
}
.fa-addOrEditEmployeesPerformanceEvaluation{
  background-image: url('./assets/images/sidebar/AddEditEmployeePerformance Evaluation.png');
  height: 25px;
  background-position: center;
}
.fa-joinImplementGovernmentResources{
  background-image: url('./assets/images/sidebar/Join_and_implement_government_resources_system_for_new_entity.png');
  height: 25px;
  background-position: center;
}
.fa-openComplainsStage{
  background-image: url('./assets/images/sidebar/Open_complains_stage.png');
  height: 25px;
  background-position: center;
}
.fa-amendOnSharedGovernmentHRManagementSystem{
  background-image: url('./assets/images/sidebar/Amends_on_shared_government_HR_management_system.png');
  height: 25px;
  background-position: center;
}
.fa-ReportsAndStaticRequest{
  background-image: url('./assets/images/sidebar/stats_reports.png');
  height: 25px;
  background-position: center;
}
.fa-OtherRequests{
  background-image: url('./assets/images/sidebar/other_request.png');
  height: 25px;
  background-position: center;
}

.mainMenu{
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  background-color: #4f5f6f;
}
.mainMenuTxt{
  /* color: #fff;
  font-weight: 400;
  font-size: 14px; */
  font-family: 'ge_ss_text_Regular' !important;
  cursor: pointer;
  padding: 0px 10px 0px 5px;
}
.menuTxt, menuTxt:hover, .subMenu a:hover{
  color:#fff;
  text-align: left;
}
.subMenu{
  padding: 8px;
  cursor:pointer;
}
.font12{
  font-size: 12px;
  padding: 0px;
}
.langTxt{
  margin-left: 5px;
  margin-right: 5px;
  /* color: #212529; */
}
.arrowLft{
  background-image: url('./assets/images/icons/sidebar_new_icon/arrows/down_trangle_arrow.png');
  width: 9px;
  background-repeat: no-repeat;
  position: relative;
  top: 7px;
  right: 0px;
}
.arrowRht{
  background-image: url('./assets/images/icons/sidebar_new_icon/arrows/down_trangle_arrow.png');
  width: 9px;
  background-repeat: no-repeat;
  position: relative;
  top: 7px;
  right: 0px;
}
.arrowDwn{
  background-image: url('./assets/images/white_up_arrow.png');
  width: 16px;
  background-repeat: no-repeat;
  position: relative;
  top: 7px;
  right: 0px;
}
.hraDatePickerAr .form-control
{
  text-align: right;
}
.txt-green { 
  color: green; 
}
.txt-yellow {
  color: yellow;
}
.txt-red {
   color: red;
}
.download-iocn{
  color: rgb(106, 153, 173);
  padding-top: 1%;
}
.download-img{
  width: 40%;
}
.delete-img{
  width: 30%;
}
/* Exemption table css */
.tableHeading{
  height : 35px;
  color : #6a99ad;
  padding : 5px;
  width:96%;
  float: left;
  font-weight: bold;
}
.deleteBtn{
  color: white;
  border-color: rgb(211, 211, 211);
  border-radius: 0px;
  padding: 8px 15px 8px 15px;
  margin: 0;
  background-color: #4f5f6f !important;
}
.plusBtn{
  color: white;
  border-color: rgb(211, 211, 211);
  border-radius: 0px;
  padding: 8px 15px 8px 15px;
  margin: 0;
  background-color: #4f5f6f !important;
}
.textValueForView {
  word-break: break-word;
}
.react-datepicker-wrapper
{
  width: 100%
}
.deleteBox{
  float: right;
}
.apexcharts-menu-icon {
  display: none;
}
.card-body{
  height: 100%;
}
.textLeft{
  text-align: left;
}
.colPad{
  padding: 10px;
}
.textRight{
  text-align: right;
}
.tableHeader{
  text-align: center;
  background-color: #9c9c9c;
  color: #fff;
  font-weight: bold;
}
.padTop20{
  padding-top: 20px;
}

/* Organization structure css */
#root,
.wrapper {
  height: 100%;
  width: 100%;
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.bar-wrapper {
  padding: 20px;
  width: 100%;
}

.tree-wrapper {
  height: 100%;
}

.previous {
  margin-left: 10px;
}
.next,
.collapse {
  display: block !important;
}
.rst__rowLabel{
  min-width: 320px;
}
.rst__rowTitle{
  font-weight: 400 !important;
}
.rst__moveHandle{
  background-color: #6a99ad !important;
  border: 1px solid #6a99ad !important;
}
.fa-sector .rst__moveHandle{
  background:  #6a99ad url('./assets/images/icons/sidebar_new_icon/Sector2.png') no-repeat center !important;
}
.fa-dept .rst__moveHandle{
  background:  #6a99ad url('./assets/images/icons/sidebar_new_icon/department2.png') no-repeat center !important;
}
.fa-section .rst__moveHandle{
  background:  #6a99ad url('./assets/images/icons/sidebar_new_icon/Section2.png') no-repeat center !important;
}
.fa-position .rst__moveHandle{
  background:  #6a99ad url('./assets/images/icons/sidebar_new_icon/position2.png') no-repeat center !important;
}

/* About page css */
.aboutTopSpace{
  background-color: white;
}
.aboutHeadings{
  font-size: 23px;
  padding: 10px 30px;
  color:#959696;
  border-bottom: 2px solid #97b8c6;
  /* border-left: 8px solid#329bd0; */
}
.aboutDetail div{
  color:#959696;
  font-size: 15px;
  padding: 10px 25px 10px 20px;
  font-weight: 400;
}
.middlespace{
  height: 40px;
}
.aboutAndFaqcontent {
  min-height: 650px;
  background-color: white;
  padding: 8px 15px 8px 15px;
}

/* FAQ page css */
.faqContent{
  width: 90%;
  margin: 0 auto;
  box-shadow: 0px 0px 5px 0px #97b8c6;
  margin-bottom: 30px;
}
.topSpaceFaq{
  height: 40px;
}
.questionTxt{
  color: #97b8c6;
  font-size: 22px;
  font-weight: 500;
}
.ansTxt{
  color: #8f8f8f;
  font-size: 18px;
  font-weight: 400;
}
.faqBox{
  background: #fff;
  padding: 15px 20px 15px 40px;
}
.faqRow{
  padding: 10px 40px 10px 40px;
}
.faqBgImage{
  position: absolute;
  z-index: 1;
}
.noPad{
  padding-left: 0px;
  padding-right: 0px;
}

/* Hierachy container css */
.nodeBase {
  cursor: pointer;
  fill: #6a99ad !important;
  stroke: white !important;
  stroke-width: 2;
}
.leafNodeBase {
  cursor: pointer;
  fill: #6a99ad !important;
  stroke: white !important;
  stroke-width: 2;
}
.nodeAttributesBase {
  fill: #6a99ad !important;
  stroke: #fff !important;
  stroke-width: 1;
  font-size: smaller;
}
.apexcharts-menu-icon {
  display: none;
}
.fluidClass {
  padding-left: 0 !important
}    
.nodeNameBase {
  fill: #6a99ad !important;
  stroke: #fff !important;
  /* stroke-width: 1; */
  font-size: 15px;
  font-family: "ge_ss_text_Regular";
  text-align: center;
}

/* Chart View */
.initechNode {
  border: solid 2px #fff;
  border-radius: 3px;
  padding: 5px;
  width: 150px;
  display: inline-block;
  background-color: #6a99ad;
  color: #fff;
  font-family: 'ge_ss_text_Regular' !important;
  font-weight: 600;
  margin-right: 10px;
}
#initechOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
  border-right: solid 3px #6a99ad;
}
#initechOrgChart .nodeLineBorderTop {
  border-top: solid 3px #6a99ad;
}
.reactOrgChart .nodeLineBorderTop{
  border-top: solid 2px #6a99ad !important;
}
.reactOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle{
  border-right: 2px solid #6a99ad !important;
}
/* End Chart View */

.middleContainer{
  position: absolute;
  width:100%;
}

.formWhiteContainer {
  background-color: #FFFFFF
}
.formWeight{
  font-weight: 400;
}
.custom-control-label{
  padding-right: 10px;
}
.Collapsible{
  cursor: pointer
}
.largeLabelAlignment
{
  display: flex;
  flex-direction: row-reverse;
}
.largeLabelAlignment1
{
  display: flex;
  flex-direction: row-reverse;
  width: 100%
}
.apexcharts-legend-text {
  font-family: "Helvetica, Arial, sans-serif";
  font-size: 14px !important;
}
text {
  font-family: "Helvetica, Arial, sans-serif";
  font-size: 16px !important;
}
.RichTextEditor__editor___1QqIU{
  height: 100px;
}
.datePicker_ar .react-datepicker-popper
{
  left: unset !important;
  right: 0px !important;
}
.react-tabs__tab-list {
    border-bottom: 0px solid #aaa !important;
    margin: 0 0 10px;
    padding: 0;
}

.parentRow:nth-child(1) .childCol ,.parentRow:nth-child(2) .childCol,.parentRow:nth-child(3) .childCol {
  /* border-radius: 20px 20px 0 0 */
}

.parentRow:nth-last-child(1) .childCol ,.parentRow:nth-last-child(2) .childCol,.parentRow:nth-last-child(3) .childCol {
  /* border-radius: 0 0 20px 20px */
}

.chooseFileLabel_ar
{
  background-color: #9c9c9c !important;
  border-color: #03A9F4 !important;
  color: #fff !important;
  padding: 7px;
  margin-bottom: 0px;
  position: absolute;
  right: unset;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}
.react-datepicker-popper{
  z-index: 11 !important;
}
.table th{
  font-size: 16px;
  font-weight: 600;
}
.richTextEditor_ar .notranslate.public-DraftEditor-content
{
   float: right;
}
.public-DraftStyleDefault-block span span {
	font-weight: unset !important;
}
.public-DraftStyleDefault-ltr
{
  direction:  unset !important;
}
.helpHeadings{
  font-size: 23px;
  padding: 10px 30px;
  border-bottom: 0.3px solid#98b7c9;
  font-family: "ge_ss_text_Regular" ;
}
.submitBtn{
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 5px;
}
.previousAndClearBtn{
  margin-left: 15px;
  margin-right: 15px;
  border-width: 1;
  color: #000; 
  border-radius: 5px; 
  border-color: #000;
}
.statusTxtForViolationGreen {
  /* background-color: #5dd55d; */
  padding: 0px 15px 0px 15px;
  /* border-radius: 25px; */
  color: #05c705;
}
.statusTxtForViolationYellow {
  /* background-color: #FFC300; */
  padding: 0px 15px 0px 15px;
  /* border-radius: 25px; */
  color: #FFC300;
}
.statusTxtForViolationRed {
  /* background-color: #ff471a; */
  padding: 0px 15px 0px 15px;
  /* border-radius: 25px; */
  color: #ff471a;
}

.statusTxtForReopen {
    background: #9f7928 30%;
    padding: 0px 15px 0px 15px;
    /* border-radius: 25px; */
    color: #ffffff;
}
.roleLabel_ar
{
  padding-right: 5px;
}
.roleLabel_en
{
  padding-left: 5px;
}
.footerText{
  padding: 15px;
  position: absolute;
  bottom: 0;
  width: 96%;
  text-align: center;
  border-top: 1px solid #ccc;
  color: #4f5f6f;
  font-weight: 400;
}

.footerTextlogin{
  padding: 15px;
  position: absolute;
  bottom: 0;
  /*width: 96%;*/
  text-align: center;
  border-top: 1px solid #ccc;
  color: #4f5f6f;
  font-weight: 400;
}
.clearfix{
  clear: both;
}
.cke_bottom.cke_reset_all {
  display: none;
}
.formSpace {
  margin-bottom: 150px; 
  margin-top : 20px;
}
.paddingTopForm{
  padding-top: 20px;
}
.form-control.white_bg{
  background-color: white !important;
}
.form-control.gray_bg {
  background-color: gray !important;
}
.datePickerClass {
  background-color: gray;
}
.datePickerBgClass .form-control:focus {
  background-color: white !important;
}
.datePickerbg2.form-control:disabled,.form-control[readonly] {
  background-color: white !important;
}
/* .viewCk span{
  display: none;
} */
.viewCk .cke_top{
  display: none;
}
.viewCk .cke_chrome{
  border: 0px !important;
}
.flagclass , .userNameTxt{
  padding: 0;
}
.userdropdownImage{
  max-width: 25px;
}
.requiredcondition{
  padding-top: 20px
}
.requiredcondition p{
  font-size: 15px;
  background-color: #FFC300;
  /* color: #F2F509; */
  font-weight: 600;
  font-family: 'ge_ss_text_Regular';
  /* text-align: justify; */
  padding: 5px
}
.currencyFormat input
{
  width: 100%;
}
.currencyFormatInputCSS
{
  width: 100%;
  padding: 1%;
  border: 1px solid #ced4da; 
  border-radius: 5px ;
}
.dashBoardHRAInfoTableSubHeaderTextStyle{
  font-weight: bold;
  text-align: center;
}
.dashBoardHRAInfoTableHeaderStyle{
  background-color: black;
  text-align: center;
}
.tableRowTextStyle
{
  text-align: center;
}
.dashboardTable  thead
{
  background-color:#7c8fa2 ;
  text-align: center;
}
 .dashboardTable.table td 
{
 
  text-align: center;
  word-wrap: break-word;
}
.dashboardTable.table th
{ 
  text-align: center;
}
.dashboardTable_en ul li {
  list-style-type : disc;
  margin-left: 38px;
  text-align: left;
  word-wrap: break-word;
}
.dashboardTable_ar ul li {
  list-style-type : disc;
  margin-right: 41px;
  text-align: right;
  word-wrap: break-word;
}
.dashboardTable_ar .Collapsible__trigger{
  text-align: right;
}
/* .dashboardTable_ar .Collapsible__trigger:after{
  position: unset !important;
  float: left;
} */
.dashboardTable_ar .Collapsible__trigger:after{
  position: relative !important;
  float: left;
  margin-left: 10px;
  margin-top: -10px;
}
.asterisk_input::after {
  content:"*"; 
  color: #e32;
  /* font-size: large; 
  padding: 0 5px 0 0; 
  display: flex;
  flex-direction: row;
  color: red; */
}
.dashBoardStyle{
  padding-bottom: 8%;
  padding-top: 15px;
}
.Collapsible 
{
  background-color: transparent !important;
}
.collapseStyle span
{
  background: #40505f ;
}
.asterisk_input{
  background: transparent !important ;
}
.collapseAr .Collapsible__trigger{
  text-align: right;
}
.collapseAr .Collapsible__trigger:after{
  position: unset !important;
  float: left;
}

/* View Container CSS */
.outerContainer{
  padding: 15px 15px 60px 15px;
}
.innerContainer{
  background-color: #fff;
  margin-bottom: 150px;
}
.viewHeading{
  padding: 10px 10px 10px 0px;
  font-size: 16px;
  font-weight: 600;
  font-family: "ge_ss_text_Regular";
  border-bottom: 1px solid #ccc;
}
.detailRow .detailTab{
  padding-bottom: 20px;
  color: #fff;
  padding-right: 0px;
}
.detailTab div{
  background-color: #f00;
  padding: 10px;
  word-break: break-all;
}
.lastDetailTab{
  padding-right: 15px !important;
}

/* preview css starts here */
.grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;             /* adjusted */
      grid-template-columns:  repeat( 4, 1fr );
  -ms-grid-rows: 270px 270px 270px 270px;        /* adjusted */
      grid-template-rows: repeat( 4, 270px );
  grid-gap: 30px;
}
.grid-container {
  /* display: grid; */
  grid-template-columns: auto auto;
  background-color: #ffffff;
  border-radius: 3px;
  /* border-bottom: 1px solid #ccc; */
}
.grid-item {
  padding: 5px 5px 5px 0px;
  height: 52px !important;
  border: 1px solid rgb(221, 221, 221) !important;


}
.grid-key {
  color: #847373;
  width: 100%;
  flex: 1;
  border-radius: 3px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.grid-value {
  padding: 1%;
  border-radius: 3px;
  max-width: 60%;
  flex: 2;
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  /* overflow-y: hidden; */
  display: flex;
  align-items: center;
}
.grid-label {
  color: #847373;
  border-radius: 3px;
  font-weight: 400;
  display: flex;
  align-items: center;
  min-height: 40px;
  width: 26%;
}
.grid-fileName {
  width: 40%;
  border-radius: 3px;
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.grid-attachment{
  width: 30%;
  display: flex;
  flex-direction: row;
}
.ckLabel {
  color: #847373;
  min-width: 250px;
  border-radius: 3px;
  font-weight: 400;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
}
.ckValue {
  padding: 1%;
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  /* overflow-x: auto; */
}

/* preview patient table css */
.patient-key {
  color: #847373;
  width: 100%;
  border-radius: 3px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.patient-value {
  padding: 1%;
  border-radius: 3px;
  max-width: 50%;
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
}
/* End preview patient table css */

/* End of preview css */

.viewDetailModal{
  width:70% !important;
  margin:auto;
}
.viewDetailModal70{
  width:78% !important;
  margin:auto ;
}
.cke_button__about_icon {
  display: none !important;
}
.noRecordFound{
  color: #212529;
  font-size: 16px;
  font-weight: 400;
}
.ar_back {
  transform: rotateY(180deg);
}
.Collapsible__trigger:after{
  font-family: 'Font Awesome\ 5 Free' !important;
  font-weight: bold;
}
.viewContainerAmendSalaryScale {
  border-radius: '5px';
  font-family: "ge_ss_text_Regular";
}
.css-2b097c-container {
  width: 100% !important;
}
.grid-container-cust{
  display: grid;
  grid-template-columns: none !important;
  background-color: #ffffff;
  border-radius: 3px;
}

/* Responsive css */
@media (max-width: 768px) {
  .serviceInfo_ar, .serviceInfo_en {
    padding: 35px 0px;
  }
  .copyright_ar, .copyright_en, .menu2 {
    text-align: right;
  }
  .copyright_en {
    text-align: left;
  }
  .loginbtn {
    width: 15vw;
  }
  .logo{
    /* width: auto; */
    height: auto;
  }
  .navigation{
    height: auto;
  }
  .list-group .list-group-horizontal{
    margin-left: 0% !important;
  }
  .particleBox{
    display: none;
  }
  .footer{
    height: auto;
  }
  
}

@media (max-width: 576px) {
  .loginbtn {
    width: 25vw;
  }
}

@media screen and (max-width: 1120px) and (min-width: 780px) {
  .serviceInfo_en .serviceInfo_ar{
    padding: 14px 0;
    text-align: left;
    align-self: flex-end;
  }
  .loginbtn {
    width: 15vw !important;
  }
  .serviceInfo_en .serviceInfo_ar{
    padding: 25px 0px;
  }
  .serviceInfo_ar h4, .serviceInfo_en h4{
    font-size:14px;
  }
  .flagclass , .userNameTxt{
    padding: 0;
  }
  .userdropdownImage{
    max-width: 20px;
  }
  .serviceInfo_ar p, .serviceInfo_en p{
  font-size: 13px;
  line-height: 1.1;
  }
  .serviceInfo_ar {
    padding: 30px 0;
    text-align: right;   
  }
  
  .services:hover .serviceInfo_ar h4, .services:hover .serviceInfo_en h4 {
    color: #FFFFFF;
    font-weight: 900;
    align-self: center;
  }

  .services:hover p {
    color: #ffffff;
    font-weight: 700;
  }
  .services {
    margin-top: 12vh;
    height: 23vh;
  }
  
  .displayDiv{
    display: none;
  }
  .servicesBlock{
    height: 87vh !important;
  }
  .btn {
    padding: 0.71rem 0.54rem;
    font-size: 0.71rem;
    margin: 0.375rem;
  }
}

@media screen and (min-width: 1120px) {
  .btn {
    padding: 0.84rem 0.75rem;
    font-size: 0.67rem;
  }
}

@media screen and (max-width: 780px){
  .serviceInfo_en .serviceInfo_ar{
    padding: 7px 0;
    text-align: left;
    align-self: flex-end;
  }
  .serviceInfo_ar h4, .serviceInfo_en h4{
    font-size:14px;
  }
  .flagclass , .userNameTxt{
    padding: 0;
  }
  .userdropdownImage{
    max-width: 20px;
  }
  .loginbtn {
    width: 24vw !important;
  }
  .serviceImage {
    margin-top: -1vh;
    float: right;
  }
.serviceInfo_ar p, .serviceInfo_en p{
  font-size: 13px;
  line-height: 1.5;
  }
  .services:hover .serviceInfo_ar, .services:hover .serviceInfo_en {
   
    transform: translate(0, -20px);
    -webkit-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    -moz-transform: translate(0, -20px);
  }
  .serviceInfo_en {
    padding: 20px 0px;
  }

  .footerDesign {
    height: 11vh;
  }

  .services:hover {
    background-color: #6a99ad;
    border: 1px solid #6a99ad;
  }
  .services:hover .serviceInfo_ar h4, .services:hover .serviceInfo_en h4 {
    color: #FFFFFF;
    font-weight: 900;
    align-self: center;
  }
  
  .services:hover p {
    color: #ffffff;
    font-weight: 700;
  }
  .services {
      margin-top: 13vh;
      height: 27vh;
  }
  .displayDiv{
    display: none;
  }
  .servicesBlock{
    height: 94vh !important;
  }
  .socialIconBar_ar, .socialIconBar_en{
    display: none;
  }
  .btn {
    padding: 0.71rem 0.54rem;
    font-size: 0.71rem;
    margin: 0.375rem;
  }
  .sibebarLink p {
    display: none;
  }
  .sibebarLink {
    padding: 35% !important;
  }
  .mainMenuTxt{
    display: none;
  }
  .headerLeftBox img{
    display: none;
  }
}

@media screen and (max-width: 575px){
  .services {
    height: 20vh !important;
    transform: unset !important;
    -webkit-transform: unset !important;
    -o-transform: unset !important;
    -moz-transform: unset !important;
  }
  .serviceImage {
    margin-top: -3vh;
    float: right;
}
.services:hover .serviceInfo_ar h4, .services:hover .serviceInfo_en h4 {
    color: #FFFFFF;
    font-weight: 900;
    align-self: center;
  }

  .services:hover p {
    color: #ffffff;
    font-weight: 700;
  }
  .servicesBlock{
    height: 180vh !important;
  }
  .socialIconBar_ar, .socialIconBar_en{
    display: none;
  }
  .btn {
    padding: 0.11rem 0.14rem;
    font-size: 0.71rem;
    margin: 0.375rem;
}
.sibebarLink p {
  display: none;
}
.sibebarLink {
  padding: 35% !important;
}
.mainMenuTxt{
  display: none;
}
.headerLeftBox img{
  display: none;
}
}

/*Custom ScrollBar CSS Starts here*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
/*Custom Scrollbar CSS ends here*/

.bonusTableAr p
{
  text-align: right
}
.bonusTableAr td{
  text-align: right
}
.bonusTableAr th{
  text-align: right
}
.amendSalScaleTablePreviewAr p
{
  text-align: right
}
.datePickerBorderColorErr .form-control
{
  border : 1px solid #dc3545 !important
}
.ckEditorStyle{
  margin-top:15px;
  margin-bottom:10px;
  -ms-overflow-style: none;
  padding: 1.1px;
}
.css-2b097c-container {
  width: 100% !important;
}
.grid-container-cust{
  display: grid;
  grid-template-columns: none !important;
  background-color: #ffffff;
  border-radius: 3px;
}
.footerSupportTxt{
    font-family: 'ge_ss_text_Regular';
    font-weight: 400;
    font-size: 16px;
}
/* For IE */
.ckBox{
  -ms-overflow-style: none;
}
/* fixed loader css */
._loading_overlay_overlay {
  position: fixed !important;
}
/* end fixed loader css */
/* Only for IE  */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .css-50etie svg {
    top: 90vh !important;
  }
  .css-50etie svg circle {
    stroke-dasharray: 100 !important;
  }
}
/* End loading spinner for IE */
.Login_LogoDiv{
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboardTable_ar .Collapsible__trigger:after{
  position: relative !important;
  float: left;
  margin-left: 10px;
  margin-top: -10px;
}
.attachmentName{
  float: left;
}
.attachmentNameForArebic{
  float: right;
}
.attachmentShortName label{
  cursor: pointer;
}
.apexcharts-zoom-icon{
  display: none;
}
.apexcharts-pan-icon{
  display: none;
}
.cke_button__table,.cke_button__image,.cke_button__horizontalrule_icon {
  display: none !important;
}
.viewContainerClass{
  padding: 0;
}
.shortNameKey{
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
}
.shortNameValue{
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.LoginHeader{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.HRALogonew{
  align-self: flex-end;
}
.sibebarLinkMin{
  padding: 15% !important;
}
.patientTable{
width: 100%;
padding-top: 35px;
}
.multipleAttachment{
width: 100%;
}
/** Text Araea Style **/
.modal-fluid .modal-content .modal-body  textarea {
  min-height: 250px;
  min-width: 750px !important;
  resize: vertical;
}
.row,.container
{
  resize: vertical;
}
.container
{
  max-width: 100% !important;
}

/* Lat Actions */

.LastActionVDdiv
{
  background:rgb(115, 43, 36);
  color: white;
  padding: 10px;
}

.ViewDeatilsActions
{
  background: rgb(115, 43, 36);;
  color: #f1f1f1;
  padding: 10px 0px;
  position: fixed;
  bottom:0;
width: 80%;
z-index: 100;
}
.ViewDeatilsActions .row{
  background-color: white;
  padding: 5px;
}
.ViewDeatilsActions .gridActionBtn
{
  font-size:12pt;
  background-color: #4f5f6f !important;
}
.gridUploadAttch
{
  background-color: rgb(115, 43, 36) !important;
  
    font-size: 12px;

}
.ViewDeatilsActions .gridActionBtAttch,.gridActionBtAttch
{
  background-color:darkgreen !important;
}
.whitetext
{
color:#F5F5F5 !important;
}

.fa-Trasol
{
  background-image: url('./assets/images/sidebar/verification-icon-5.png');
  height: 25px;
  background-position: center;
}

.fa-Employeereport
{
  background-image: url('./assets/images/sidebar/employeesicon.png');
  height: 25px;
  background-position: center;
}

.tooltipicon
{
  background-image: url('./assets/images/tooltipicon.png');
  height: 25px;
  background-position: center;
}

.Spnaction div
{
  
}

.fa-SickLeave
{
  
    background-image: url('./assets/images/sidebar/Amends_on_shared_government_HR_management_system.png');
    height: 25px;
    background-position: center;
  
}

.fa-Promotion
{
  
  background-image: url('./assets/images/sidebar/Join_and_implement_government_resources_system_for_new_entity.png');
    height: 25px;
    background-position: center;
  
}


.Empty1 {
  background: url("./assets/images/Survey/05-u.png") ;background-size: 100px 100px !important;
}

  .Empty1:hover {
      background: url("./assets/images/Survey/05.png") ;background-size: 100px 100px !important;
  }

.Filled1 {
  background: url("./assets/images/Survey/05.png") ;background-size: 100px 100px !important;
}

    .Empty2 {
  background: url("./assets/images/Survey/04-u.png") ;background-size: 100px 100px !important;
}

  .Empty2:hover {
      background: url("./assets/images/Survey/04.png") ;background-size: 100px 100px !important;
  }

.Filled2 {
  background: url("./assets/images/Survey/04.png") ;background-size: 100px 100px !important;
}

    .Empty3 {
  background: url("./assets/images/Survey/03-u.png") ;background-size: 100px 100px !important;
}

  .Empty3:hover {
      background: url("./assets/images/Survey/03.png") ;background-size: 100px 100px !important;
  }

.Filled3 {
  background: url("./assets/images/Survey/03.png") ;background-size: 100px 100px !important;
}


    .Empty4 {
  background: url("./assets/images/Survey/02-u.png") ;background-size: 100px 100px !important;
}

  .Empty4:hover {
      background: url("./assets/images/Survey/02.png") ;background-size: 100px 100px !important;
  }

.Filled4 {
  background: url("./assets/images/Survey/02.png") ;background-size: 100px 100px !important;
}


    .Empty5 {
  background: url("./assets/images/Survey/01-u.png") ;background-size: 100px 100px !important;
}

  .Empty5:hover {
      background: url("./assets/images/Survey/01.png") ;background-size: 100px 100px !important;
  }

.Filled5 {
  background: url("./assets/images/Survey/01.png") ;background-size: 100px 100px !important;
}

.form-group label
{
  display: flex !important;
}

.custom-file-inp span
{
margin: 0px 80px;
}

.col-md-2
{
  padding :0px !important;
}

.fixcusotmbtn

{
  padding:15px  !important;
  margin:3px 0px !important;
  border-color: #fff !important;
}
.flagclass
{
  color: #fff !important;

}
.Ads
{
 
  color: #fff !important;
direction: rtl !important;
text-align:right !important;
padding : 10px !important;
background-color: rgb(192, 39, 28) !important;
}
.AdsWhite
{
 
  color: #000 !important;
direction: rtl !important;
text-align:right !important;
padding : 10px !important;
background-color: rgb(254, 254, 254) !important;
}
.AdsTitle
{
  color: #fff !important;
direction: rtl !important;
text-align:right !important;
/* padding : 10px !important; */
background-color: rgb(192, 39, 28) !important;
text-align: center !important;
}
.AdsTitleWhite
{
  color: #000 !important;
direction: rtl !important;
text-align:right !important;
/* padding : 10px !important; */
 background-color: rgb(254,254,254) !important; 
text-align: center !important;
}
.Adsdiv
{
  background-color: rgb(192, 39, 28) !important;
}
.Adsh1 {
  font-weight: bolder;padding: 10px 0px 0px 0px !important;
  width: 100%;
}
.Adsh1White {
  font-weight: bolder;padding: 10px 0px 0px 0px !important;
  width: 100%;
}

.AdSpanClose
{
  font-size: 18pt !important;
  background-color: #05c705 !important ;
  
}
.AdsCloseButton
{
  cursor: pointer !important;
 padding :12px !important;
 text-align: center !important;
  font-size: 16px !important;
  background-color:rgb(10, 82, 10) !important ;
}
.AdsEn
{
text-align: left !important;
direction: ltr !important;
}

.surveyimages
{
  width: 100px !important;
    height: 100px !important;
}


.WorkShopDatespopup
{
  background: url("./assets/images/WorkShops.png") ;
}

.viewDetailModal76{
  width:76% !important;
  margin:auto;
}

.ArabicLegalOpinon
{
font-size: 11pt !important;
text-align: right !important;
direction: rtl !important;
padding: 0 4px;
}

.LegalOpinon
{
  font-size: 10pt !important;
  text-align: left !important;
  direction: ltr !important;
  padding: 0 4px;
}





.AddNewDateButton
{
  background-color: rgb(10, 82, 10) !important;
  font-size: 14px!important;
  font-weight: bold!important;

}
.buttonsrow
{
margin-top: 5px;
}
.buttonsrow .row {
  text-align: center !important;
  padding-right: 40%;
  border-top: Gray 2px solid;
  padding-top: 2%;
}
.GreenBG
{
  background-color: rgb(10, 82, 10) !important;
}
.RedBG
{
  background-color:  rgb(192, 39, 28) !important;
}
/* .invalid-feedback
{
  display: inline-block !important;
} */

ol.DisciplinaryboardIntrolist
{
  
  margin: 10px !important;
  padding: 10px !important;
  list-style-type:udecimal !important;
  width: 100%;


}

ol.DisciplinaryboardIntrolist li{
  list-style-type:decimal;
  padding-bottom: 10px !important;
}

.viewdetailnewtable,.viewdetailnewtableEmployees,.viewdetailnewtableProjects
{
  width: 100% !important;
}
.viewdetailnewtable th 
{
  border:#4f5f6f solid 1px;
  min-width: 20% !important;
  width: 16% !important;
  font-size: 14px !important;
  font-weight:bold !important;
  padding: 0px 5px !important;
}

.viewdetailnewtable tr td
{
  
    border:#4f5f6f solid 1px;
    min-width: 20% !important;
    width: 16% !important;
    font-size: 14px !important;
  
    padding: 0px 5px !important;
  

}

.viewdetailnewtableEmployees th 
{
  border:#4f5f6f solid 1px;
  min-width: 20% !important;
  width: 12% !important;
  font-size: 14px !important;
  font-weight:bold !important;
  padding: 0px 5px !important;
}

.viewdetailnewtableEmployees tr td
{
  
    border:#4f5f6f solid 1px;
    min-width: 20% !important;
    width: 12% !important;
    font-size: 14px !important;
  
    padding: 0px 5px !important;
  

}

.viewdetailnewtableProjects th 
{
  border:#4f5f6f solid 1px;
  min-width: 11% !important;
  width: 11% !important;
  font-size: 14px !important;
  font-weight:bold !important;
  padding: 0px 5px !important;
}

.viewdetailnewtableProjects tr td
{
  
    border:#4f5f6f solid 1px;
    min-width: 11% !important;
    width: 11% !important;
    font-size: 14px !important;
  
    padding: 0px 5px !important;
  

}

.ArabicQMark
{
  content: url('./assets/images/ArabicQMark.png');
  right: 0px;
  transform: translate(0,-5px);
  -webkit-transform: translate(0,-5px);
  -o-transform: translate(0,-5px);
  -moz-transform: translate(0,-5px);
  width: 30px;
}
.EnglishQMark
{
  content: url('./assets/images/EnglishQMark.jpg');
  left: 0px;
  transform: translate(0,-5px);
  -webkit-transform: translate(0,-5px);
  -o-transform: translate(0,-5px);
  -moz-transform: translate(0,-5px);
  width: 30px;
}
.infoIcon
{
  content: url('./assets/images/info.png');
  left: 0px;
  transform: translate(0,-5px);
  -webkit-transform: translate(0,-5px);
  -o-transform: translate(0,-5px);
  -moz-transform: translate(0,-5px);
  width: 30px;
}
.Aralign
{
  direction: rtl !important;
  text-align: right !important;
  
  
}
.padding15px
{
  padding:0 30px !important;
}

.paddingtop15px
{
padding-top: 30px !important ;
}

.seperator
{
  border-bottom: 5px solid Black !important;
  margin:0px 30px !important;
}
.EditCollapseH5
{
  padding: 15px !important;
  display:inline-flex;
}

.EditCollapsebtn
{
  margin: 15px !important;
  display:inline-flex;
}
.divCollapsebtnspace
{
  
  border-bottom: 2px solid !important;
  border-color: rgb(115, 43, 36) !important;
  margin:5px 5% !important;
}
.divCollapsebtn
{
  margin: 15px !important;
  
  

  
}
.CloseCollapsebtn
{
  margin: 15px  40% !important;
}

.IntivateNeedUpdate
{
  color:red !important;
  
  font-weight: bolder !important;
  padding-right: 5px !important;
}

.TDtextLeft
{
  text-align: left !important;
  padding-left: 15px !important;
}


.Covid19 
{
margin:0px 15px !important;

background-color:gold !important;
}

.Covid19 div
{
  display: flex;flex-direction: row-reverse;
  align-items: center;justify-content: center;background-color: gold!important;
  ;padding-top: 15px;padding-bottom: 15px;padding-right: 15px;padding-left: 15px;
}
.Covid19 div span
{
   background-color:gold !important; border-radius: 50px;
   height: 25px;margin-right: 2px;padding: 3px;color: #004d00 !important;margin-top: 0px;font-size: 16px;font-weight: bold;
}
.DateFilteraionBackground
{
  background-color: gold;
}

.DateFilterationTitle, .DateFilteraionBackground span
{
  color: #0b0b0b;
  font-weight: bold;
  font-size: 14pt;
}

.DateFilteraionBackground .react-datepicker-wrapper
{
  width:85% !important;
  padding:0px 5px !important;
}

.DateFilteraionBackground .submitBtn 
{
  margin:0px !important;
}

.DateFilteraionBackground .DateFilteraionClearBtn
{
  margin:0px 10px !important;
}

.AdsTable tr td
{
  border: black;
    border-width: thin;
    border-style: double;
    padding:4px;
}

.boldspan
{
  font-weight: bold ;
  margin-top: 5px !important;
}

/* alert */

@import url(https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.min.css);

  .alert {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    font-weight: bold;
    font-family: inherit;
    margin-top: -20px !important;
    margin-bottom: -10px !important;
  }

  .alert .inner {
    display: block;
    padding: 5px 5px 7px 5px;
    margin: 6px;
    border-radius: 3px;
    border: 1px solid rgb(180, 180, 180);
    background-color: rgb(212, 212, 212);
  }

  .alert .close {
    float: right;
    margin: 3px 12px 0px 0px;
    cursor: pointer;
  }

  .alert .inner,
  .alert .close {
    color: rgb(88, 88, 88);
  }

  .alert input {
    display: none;
  }

  .alert input:checked~* {
    animation-name: dismiss, hide;
    animation-duration: 300ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 0s, 100ms;
  }



  .alert.info .inner {
    border: 1px solid yellow;
    background-color: yellow;
  }

  .alert.info .inner,
  .alert.info .close {
    color: rgb(58, 135, 173);
  }

  .alert a {
    text-decoration: none;
    color: #fff;
    background: yellow;
    padding: 10px 15px;
    border-radius: 10px;
    transition: all 0.5s ease;

  }

  .alert a:hover {
    color: #fff;
    background: #ae1c2c;

  }



  @keyframes dismiss {
    0% {
      opacity: 1;
    }

    90%,
    100% {
      opacity: 0;
      font-size: 0.1px;
      transform: scale(0);
    }
  }

  @keyframes hide {
    100% {
      height: 0px;
      width: 0px;
      overflow: hidden;
      margin: 0px;
      padding: 0px;
      border: 0px;
    }
  }